.button-link {
  transition: none !important;
  &.text-primary:hover {
    color: #126693 !important;
  }

  &.text-danger:hover {
    color: #a71d2a !important;
  }
}
