.symptom-check-btn {
  &[type='button'] {
    color: white;
    opacity: 0.6;
    &:hover,
    &:active {
      color: white;
    }

    &.expected-success {
      background-color: rgb(37, 164, 82);
    }

    &.expected-failure {
      background-color: rgb(255, 0, 4);
    }

    &.selected {
      opacity: 1;
    }
  }
}
