.my-badge {
  .qrcode-image {
    width: 100%;
    &__container {
      padding: 0 1rem 1rem 1rem;
    }
    img {
      width: 100%;
    }
  }
  .check-in-status {
    display: flex;
    justify-content: space-around;
    align-items: top;
    padding: 0 2rem;
    &__item {
      text-align: center;
      img {
        width: 4rem;
        height: 4rem;
      }
      p {
        opacity: 0.7;
      }
      &__title {
        font-weight: 500;
        font-size: 13px;
        margin: 1rem 0 0;
      }
      &__completed {
        font-size: 12px;
        margin: 0.25rem 0 0;
      }
    }
  }
}
