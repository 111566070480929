.ham-nav {
  position: absolute;
  top: 0;
  right: 0 !important;
  left: unset !important;
  background-color: transparent !important;
  width: 76px;
}

button:focus {
  outline: 0 !important;
}

.settings-tooltip {
  position: fixed;
  top: 50px;
  right: 16px;
  background: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  z-index: 2021;
}

.close-tooltip {
  display: none;
}

.slide {
  margin-top: 2px;
  .toggle {
    display: flex;
    align-items: center;
    input[type='checkbox'] {
      visibility: hidden;
      &:checked + label {
        opacity: 0.3;
        transition: 0.25s ease-out;
        &:before {
          transform: translatex(15px);
        }
      }
    }
    label {
      display: block;
      border-radius: 99em;
      width: 32px;
      height: 17px;
      border: 2px solid;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      margin-bottom: 0;

      &:before {
        content: '';
        display: block;
        position: absolute;
        transition: 0.25s ease-out;
        width: 9px;
        height: 9px;
        background-color: currentcolor;
        border-radius: 50%;
        transform-origin: 50% 50%;
        top: 2px;
        left: 2px;
      }
    }
  }
}

.guest-symptom-check {
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.5rem;
    .app-navbar {
      h1 {
        font-size: 2em;
      }
      h4 {
        font-size: 1.2em;
      }
    }

    input {
      font-size: inherit;
    }

    .btn.btn-screen-submit {
      font-size: inherit;
      font-weight: bold;
      height: auto;
      padding: 1rem 2rem;
    }

    .btn.btn-yesno {
      box-sizing: border-box;
      width: 300px;
      height: 150px;
      font-size: 50px;
      font-weight: bold;
      transition: none;
    }

    .btn.selected {
      border: 5px solid black;
    }
  }

  .container {
    overflow-x: hidden;
  }

  .bg-light {
    background-color: #f6f7f8;
  }

  .question-container {
    background-color: #fff;
    border-top: 1px solid #dfe3e6;
    border-bottom: 1px solid #dfe3e6;
    padding: 1rem 2rem;
    margin-left: -2rem;
    margin-bottom: 1rem;
    width: 100vw;

    .question-row {
      margin-bottom: 0;
    }

    @media screen and (min-width: 576px) {
      margin-left: 0;
      width: auto;
      border-left: 1px solid #dfe3e6;
      border-right: 1px solid #dfe3e6;
      border-radius: 4px;
    }
  }

  .phone-number-container.is-invalid ~ .invalid-feedback {
    display: block;
  }

  .country-code-select.custom-select {
    height: 50px;
    background-color: #f5f7fa;
    color: #909399;
    flex: 0 0 130px;
  }

  .input-group-append {
    flex: 1;
  }
  .input-group-append > input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
