.guest-survey-result {
  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 1.5rem;

    &.results-page {
      h1 {
        font-size: 2em;
      }

      h4,
      small,
      input,
      label {
        font-size: inherit;
      }
    }

    .btn.btn-yesno,
    .btn.btn-screen-submit {
      font-size: inherit;
      font-weight: bold;
      height: auto;
      padding: 1rem 2rem;
    }

    .custom-switch {
      padding-left: 2.25rem;
      font-weight: bold;
    }

    .custom-control-label {
      padding-left: 2rem;
    }

    .custom-switch .custom-control-label::before {
      top: 0.25rem !important;
      left: -2.25rem;
      height: 2rem;
      width: 3.5rem;
      pointer-events: all;
      border-radius: 1rem;
    }

    .custom-switch .custom-control-label::after {
      top: calc(0.25rem + 2px) !important;
      left: calc(-2.25rem + 2px);
      width: calc(2rem - 4px);
      height: calc(2rem - 4px);
      background-color: #adb5bd;
      border-radius: 2rem;
      transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-transform 0.15s ease-in-out;
      transition: transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      transition: transform 0.15s ease-in-out,
        background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    }

    .custom-switch
      .custom-control-input:checked
      ~ .custom-control-label::after {
      background-color: #fff;
      -webkit-transform: translateX(1.5rem);
      transform: translateX(1.5rem);
    }

    & .submit-message.submit-message {
      // 720px is the max-width on iPad
      width: calc(720px - 4rem);
    }
  }

  .qr_code-header {
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .qr_code-image {
    width: 100%;
    min-height: 200px;
    border: 2px solid #000;

    &__container {
      margin: 3rem auto;
      max-width: 400px;

      h4 {
        font-size: 20px;
        margin-top: 1rem;
      }
    }

    img {
      width: 100%;
    }
  }

  .manual-input {
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .submit-message {
    position: fixed;
    top: 20px;
    transform: translateY(-300%);
    transition: all 0.3s ease;
    width: calc(100% - 4rem);
  }

  .custom-switch .custom-control-label {
    &::before {
      top: calc(50% - 0.5rem);
    }
    &::after {
      top: calc(50% - 0.5rem + 2px);
    }
  }

  .link {
    cursor: pointer;
    font-size: inherit;
  }
}

// these only apply for horizonal
@media print {
  .print {
    position: relative;
  }
  .rotate-wrap {
    width: 100vh;
    transform-origin: top left;
    transform: rotate(-90deg) translateX(-100vh);
  }
  .qr-wrap {
    display: flex;
  }
  .qr_code-image {
    width: 45%;
    img {
      width: 100%;
    }
  }
  .qr_code-note {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .guest-name {
    width: 100%;
  }
}
