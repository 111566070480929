$primary-color: #1a95d7;
$primary: #1a95d7;

$theme-colors: (
  'primary': #1a95d7,
);

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

body {
  font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue',
    Helvetica, Arial, 'Lucida Grande', sans-serif;
  font-weight: 300;
}

h1 {
  font-weight: 700;
}

.splash {
  background-color: $primary-color;
  color: white;
  text-align: center;
  padding-bottom: 0px;
  margin-bottom: 4rem;
}

.btn-primary {
  background-color: #1a95d7;
  border: none;
  border-radius: 2px;
  font-weight: 700;

  &:active,
  &:visited,
  &:hover {
    background-color: #1783bd;
  }
}

.btn-secondary {
  background-color: #adb8bd;
  border: none;
  border-radius: 2px;
  font-weight: 700;

  &:active,
  &:visited,
  &:hover {
    background-color: #969fa3;
  }
}

.btn-danger {
  background-color: #ff0006;
  border: none;
  border-radius: 2px;
  font-weight: 700;

  &:active,
  &:visited,
  &:hover {
    background-color: #e60004;
  }
}

.container {
  padding-left: 2rem;
  padding-right: 2rem;
}

.app-navbar {
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 28px;
  }
  h4 {
    font-size: 13px;
  }

  .btn {
    height: 38px;
    img {
      margin-right: 8px;
    }
  }
}

.standard-page {
  padding-top: 30px;
  padding-bottom: 55px;
  min-height: 100vh;
}

.question-row {
  background-color: white;
  margin-bottom: 2rem;
  li {
    font-weight: bold;
  }

  .options-bullets {
    padding-left: 1.2rem;
    margin-top: 0.5rem;
  }

  .btn {
    &.is-invalid {
      border: 1px solid #dc3545;
    }
  }
}

.btn.btn-yesno {
  font-size: 15px;
  border: none;
  border-radius: 2px;
  height: 36px;
  min-width: 4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.btn.btn-screen-submit {
  background-color: $primary-color;
  border-radius: 2px;
  border-color: transparent;
  font-weight: bold;
  font-size: 16px;
  height: 50px;

  &:active,
  &:visited,
  &:hover {
    background-color: #1783bd;
  }
}

.border-top {
  border-top: 1px solid #979797;
  padding-top: 15px;
}

.results-page {
  padding-top: 30px;
  padding-bottom: 55px;
  min-height: 100vh;

  .results-header {
    border-bottom: 1px solid lightgray;
  }

  h1 {
    font-size: 28px;
    margin: 1rem 0;
  }

  h4 {
    font-size: 15px;
  }

  ul {
    margin-bottom: 0px;
  }
}

.todays-screen-table {
  width: 100%;
  background-color: white;
  border: 1px solid #dfe3e6;

  thead > tr {
    background-color: #eaeef1;
  }
}

/* Nav Footer */

.nav {
  border-top: 1px solid #dce1e4;
  background-color: #e8eaeb;
  height: 54px;

  .nav-link {
    padding: 8px 5px;
    flex: 1;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: black;
    border-radius: 0;
    background-color: #e8eaeb;
    svg {
      height: 20px;
      margin-bottom: 2px;
    }
    &.active {
      background-color: #e8eaeb;
      color: #0097e3;
      svg path {
        stroke: #0097e3;
      }
      svg rect {
        stroke: #0097e3;
        fill: transparent;
      }
      svg circle {
        stroke: #0097e3;
      }
    }
  }

  img.nav-icon {
    display: block;
    margin: auto;
  }
}

form {
  label {
    font-size: 13px;
    color: #979797;
  }
  input.form-control {
    height: 50px;
  }
}

.form-group.required {
  .col-form-label::before {
    content: '*';
    color: #ff0000;
    display: inline-block;
    margin-right: 4px;
  }
}

.forgot-passsword-link {
  color: #1a95d7;
  font-size: 13px;
}

a {
  font-weight: bold;
}

.bottom-bar {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: white;
  .nav {
    margin-top: 0px;
  }
}

.custom-control-lg .custom-control-label::before,
.custom-control-lg .custom-control-label::after {
  top: 0.1rem;
  left: -2rem;
  width: 1.25rem;
  height: 1.25rem;
}

.custom-control-lg .custom-control-label {
  margin-left: 0.5rem;
  font-size: 1rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #1a95d7;
}

.custom-checkbox
  .custom-control-input:checked:focus
  ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(26, 149, 215, 1);
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25);
}
.custom-checkbox .custom-control-input:active ~ .custom-control-label::before {
  background-color: #1a95d7;
}

.notifications-list {
  background-color: #fff5f6;
  padding: 0;
  margin: 0;
  li {
    .notification-warning {
      display: flex;
      align-items: center;
      font-size: 15px;
    }
    border-bottom: 1px solid red;
    list-style: none;
    padding: 1rem 1.3rem;
    a {
      font-weight: normal;
      color: black;
    }
  }
}

.email-verified {
  min-width: 57px;
  min-height: 15px;
  border-radius: 2px;
  background-color: #25a452;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 4px;
}

.email-verified-icon {
  width: 13px;
  height: 10px;
  object-fit: contain;
  margin-right: 2px;
}

.email-verified-text {
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: #ffffff;
}
