.results-page {
  .button-group {
    margin-bottom: 1rem;
  }

  .btn {
    color: white;
    font-weight: bold;
    border-radius: 0;

    &:hover {
      color: white;
    }

    &.go-to-badge {
      background-color: #1a95d7;
    }
    &.go-to-daily-screen {
      background-color: #979797;
    }
  }
}
