.account-page {
  .country-code-select.custom-select {
    height: 50px;
    background-color: #f5f7fa;
    color: #909399;
    flex: 0 0 130px;
  }
  .input-group-append {
    flex: 1;
  }
  .input-group-append > input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.note {
  color: #909399;
}
